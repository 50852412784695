import React from 'react';
import {Form, Link, redirect, useActionData, useLoaderData, useParams, useRouteLoaderData} from "react-router-dom";
import MemberFormFields from "../components/member-form-fields";
import formDataTransformer from "../utils/form-data-transformer";
import relationship_labels from "../utils/relationship-type-labels";
import toMembersOption from "../utils/to-member-options";
import MultiField from "../components/multi-field";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";
import getValidationErrors from "../utils/get-validation-erros";

export async function loader({ request }) {
    const url = new URL(request.url);
    const fromMemberId = url.searchParams.get('fromMemberId');
    const type = url.searchParams.get('type');

    return { fromMemberId, type };
}

export async function action({ request, params }) {
    const { genogramId } = params;
    const formData = await request.formData();
    const data = formDataTransformer(Object.fromEntries(formData));

    const response = await fetch(
        `${resolveApiEndpoint()}/api/genograms/${genogramId}/members`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify(data)
        }
    );

    switch(response.status) {
        case 400:
            const responseBody = await response.json()
            return { errors: getValidationErrors(responseBody.reasons), data};
        case 500:
            const body = await response.text();
            return { errors: { global: ['Что-то пошле не так:', body] }, data};
        default:
            const member = await response.json();
            return redirect(`/genograms/${genogramId}/members/${member.memberId}`);
    }
}

export default function MemberNew() {
    const { genogramId } = useParams();
    const { fromMemberId, type } = useLoaderData();
    const { errors, data = {initialRelationships: [{fromMemberId, type}]} } = useActionData() || [];
    const { genogram: { members } } = useRouteLoaderData('genogram');

    return (
        <Form className={'my-3'} method="post">
            <div className={'d-flex my-3'}>
                <Link to={`/genograms/${genogramId}`} relative={'path'} className={'btn btn-outline-secondary me-auto'}>
                    <i className="bi bi-diagram-3"/>
                </Link>
                <button type={'submit'} className={'btn btn-success'}>
                    <i className="bi bi-floppy"/>
                </button>
            </div>
            <h2 className={'my-2 text-center'}>Новый Участник</h2>
            <MemberFormFields member={data} readOnly={false} errors={errors}/>
            <MultiField label={'Связь'} pluralLabel={'Связи'} member={data} errors={errors}
                        name={'initialRelationships'} subFields={[
                {name: 'fromMemberId', type: 'select', label: 'Связь От', options: toMembersOption(members), placeholder: 'Не Выбрано', required: true},
                {name: 'type', type: 'select', options: relationship_labels, label: 'Тип Связи', placeholder: 'Без Связи', required: true},
                {name: 'comment', type: 'text', label: 'Комментарий'},
            ]}/>
        </Form>
    )
}