import * as React from "react";
import {Link} from "react-router-dom";
import relationships_labels from "../utils/relationship-type-labels";
import MemberKeyInfo from "./member-key-info";

export default function RelationshipReadOnlyFormFields({relationship}) {

    return (
        <div className={`py-1 my-2 d-flex justify-content-evenly`}>
            <Link
                to={`/genograms/${relationship.genogramId}/members/${relationship.fromMemberId}`}
                relative={'path'}
                className={'text-reset my-1 ps-1'}>
                <MemberKeyInfo member={relationship.from}/>
            </Link>
            <hr className={'border-top border-secondary-subtle border-3'} style={{'width': '10%'}}/>
            <span className={'text-reset text-decoration-none my-1 ps-1'}>
                {relationships_labels[relationship.type] || relationship.type}
            </span>
            <hr className={'border-top border-secondary-subtle border-3'} style={{'width': '10%'}}/>
            <Link
                to={`/genograms/${relationship.genogramId}/members/${relationship.toMemberId}`}
                relative={'path'}
                className={'text-reset my-1 ps-1'}>
                <MemberKeyInfo member={relationship.to}/>
            </Link>
        </div>
    )
}
