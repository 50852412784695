import FormField from "./form-field";
import * as React from "react";

export default function CommentFormFields({members, comment, readOnly = true}) {

    return (
        <>
            <input type={'hidden'} name={'version'} value={comment?.version}/>
            {[
                {name: 'comment', type: 'text', label: 'Комментарий', required: true},
                {name: 'memberId', type: 'select', label: 'Участник', options: members, placeholder: 'Не Выбрано'},
                {name: 'author', type: 'text', label: 'Автор', required: true}
            ].map(fieldInfo => (
                <FormField member={comment} readOnly={readOnly} {...fieldInfo}/>
            ))}
        </>
    )
}
